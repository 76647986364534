@import '../../../../scss/theme-bootstrap';

.sticky-footer-chat {
  display: block;
  #{$rdirection}: 15px;
  position: fixed;
  top: auto;
  width: auto;
  z-index: 100;
  bottom: 88px;
  @include breakpoint($medium-up) {
    #{$rdirection}: 40px;
    top: auto;
    bottom: 120px;
    #{$ldirection}: auto;
  }
  &__icon {
    width: 80px;
    height: 60px;
  }
}
